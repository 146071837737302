<!-- 配置行业 -->
<template>
  <div class="configIndustry">
    <div class="card top_card">
      <div class="logo_box">
        <img :src="toolInfo.image" alt="" />
      </div>
      <div class="tool_info">
        <div class="tool_name">
          <span>{{ toolInfo.questionnaireName }}</span>
        </div>
        <!-- 根据工具类型判断展示信息 -->
        <div class="info_box">
          <div class="row" v-for="(item, index) in toolInfo.toolQuestionsResultList" :key="index">
            <div class="item">
              <span>{{ item.questionsType }}</span>
              <span>{{ item.num }}题</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card body_card">
      <div class="row">
        <span>行业标签：</span>
        
      </div>
    </div>
    <div class="card foot_btn">
      <!-- @click="saveQuestionnaire" -->
      <el-button type="primary">上架</el-button>
      <!-- @click="addToolDialogClosed" -->
      <el-button>取消</el-button>
    </div>
  </div>
</template>

<script>
import { getQuestionnaireDetail } from '@/api/diagnoseTool.js'

export default {
  name: 'configIndustry',
  data() {
    return {
      id: null, //工具id
      toolInfo: {}, //工具信息
      type: '1' //临时
    }
  },
  created() {
    this.id = this.$route.query.id
    this.search()
  },
  methods: {
    //
    async search() {
      const { data: res } = await getQuestionnaireDetail({ id: this.id })
      if (res.resultCode === 200) {
        this.toolInfo = res.data
      } else {
        this.$message.error('获取工具信息失败!')
      }
    }
  }
}
</script>

<style lang="less" scoped>
.card {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 8px;
}
.top_card {
  display: flex;
  margin-bottom: 10px;
  .logo_box {
    overflow: hidden;
    width: 100px;
    height: 100px;
    margin-right: 20px;
    border-radius: 50%;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .tool_info {
    .tool_name {
      margin-bottom: 10px;
      span {
        font-weight: 500;
        font-size: 18px;
        color: #333;
      }
    }
    .info_box {
      display: flex;
      flex-flow: column;
      align-items: center;
    }
  }
  .row .item {
    display: flex;
    justify-content: space-between;
    min-width: 100px;
    margin-right: 20px;
    span {
      color: #999;
    }
  }
}
.body_card {
  min-height: 60vh;
}
.foot_btn {
  height: 80px;
}
.row {
  display: flex;
  margin-bottom: 10px;
}
</style>